<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Paparan Pemda Provinsi</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">No</th>
                                          <th rowspan="2">Nama Provinsi</th>
                                          <!-- <th style="text-align:center;" colspan="2">Verifikasi PM</th>
                                          <th rowspan="2">Organisasi Pengusaha</th>
                                          <th rowspan="2">Skor Akhir Verifikasi</th> -->
                                          <th rowspan="2">Penilaian Paparan</th>
                                          <th rowspan="2">Nilai Paparan</th>
                                          <th rowspan="2">Catatan</th>
                                          <th style="text-align:center;" colspan="2">Unduh</th>
                                      </tr>
                                      <tr>
                                          <!-- <th>PTSP</th>
                                          <th>PPB</th> -->
                                          <th>PPT</th>
                                          <th>Lampiran</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td><div style="width:150px">{{row.nama_peserta}}</div></td>
                                          <!-- <td><div style="width:120px">{{format_desimal(row.skor_pm)}}&nbsp; &nbsp;</div></td>
                                          <td><div style="width:120px">{{format_desimal(row.skor_ppb)}}&nbsp; &nbsp;</div></td>
                                          <td><div>{{format_desimal(row.skor_hipmi)}}&nbsp; &nbsp;</div></td>
                                          <td><div style="width:50px">{{format_desimal(row.total_pm)}}</div></td> -->
                                          <td>
                                            <div style="width:150px" v-if="row.status ==='Unlock'">
                                              <b-button v-if="row.idpenilaian ==null" variant="success" v-b-modal.modal-input @click="getdataprovinsi(row.idpenilai,row.idpeserta)">Input Nilai</b-button>
                                              <b-button v-else variant="success" v-b-modal.modal-edit @click="getdataprovinsiedit(row.idpenilaian)">Ubah Nilai</b-button>
                                            </div>
                                          </td>
                                          <td><div style="width:50px">{{format_desimal(row.nilai_pemaparan)}}</div></td>
                                          <td>
                                              <button data-toggle="modal" data-target=".catatan" @click="getcatatan(row.idpeserta)" style="margin:5px" class="btn btn-primary">Lihat</button>
                                          </td>
                                          <td>
                                            <div v-if="row.file_ppt_pemaparan != null">
                                                <a :href="row.file_ppt_pemaparan" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                          </td>
                                          <td>
                                            <div v-if="row.file_lampiran_pemaparan != null">
                                                <a :href="row.file_lampiran_pemaparan" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
                
                <b-modal
                v-model="show"
                id="modal-input"
                ref="modal"
                size="xl"
                title="Form Input Nilai"
                @show="resetModal"
                :no-close-on-backdrop="true"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="inputnilai">
                <div class="alert alert-primary d-flex align-items-center" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <div>
                    <span style="padding-right:20px"> </span> Masukkan Penilaian Anda dengan rentang 1-100
                  </div>
                </div>
                <div style="overflow-x:auto;">
                  <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="tg-1wig" colspan="2">Kriteria Penilaian</th>
                      <th class="tg-1wig" colspan="2">Indikator Penilaian</th>
                      <th>Nilai</th>
                      <th class="tg-0lax">Tolak Ukur</th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Sangat </span><span style="font-weight:700">Bagus (85 sd 100)</span></th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Bagus </span><span style="font-weight:700">(70 sd 84,9)</span></th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Kurang</span><span style="font-weight:700"> Bagus (65 sd 69,9)</span><br></th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Tidak </span><span style="font-weight:700">Bagus (dibawah 65)</span><br></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">1</span></td>
                      <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penyajian</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">data</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">informasi</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kelengkapan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">data</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">informasi</span> </td>
                      <td ><b-form-group invalid-feedback="Nilai is required" :state="p1a1State"> <b-form-input type="number" step="any" style="width:100px" v-model="p1a1" min="1" max="100" :state="p1a1State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kelengkapan bahan paparan atas kriteria yang ada dalam Penilaian Mandiri </span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kriteria dan indikator penilaian telah dipaparkan dengan jelas</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kriteria dipaparkan, namun ada sebagian indikator yang tidak dipaparkan dengan jelas</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ada sebagian kecil baik kriteria maupun indikator penilaian yang tidak dipaparkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Sebagian besar kriteria dan indikator penilaian tidak dipaparkan dengan jelas</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Validitas data dan informasi</span></td>
                      <td><b-form-group invalid-feedback="Nilai is required" :state="p1b1State"> <b-form-input type="number" v-model="p1b1" step="any" min="1" max="100" :state="p1b1State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kebenaran atas kriteria penilaian mandiri yang dipaparkan dan kebenaran bukti-bukti dokumen dan aktivitas yang telah dilakukan (arsip konvensional, arsip digital, maupun bukti fisik dalam paparan)</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan sangat valid, sehingga dapat menunjukkan kebenaran seluruh kriteria dan indikator penilaian kinerja. </span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan valid, sehingga dapat menunjukkan kebenaran seluruh atau sebagian kriteria dan atau indikator penilaian kinerja.</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan cukup valid, sehingga dapat menunjukkan kebenaran seluruh atau sebagian kriteria dan atau indikator penilaian kinerja.</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan kurang valid, sehingga kurang dapat menunjukkan kebenaran kriteria dan indikator penilaian kinerja.</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax" rowspan="6"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">2</span></td>
                      <td class="tg-0lax" rowspan="6"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemaparan</span></td>
                      <td class="tg-0lax" rowspan="4"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                      <td class="tg-0lax" rowspan="4"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Teknik pemaparan</span> </td>
                      <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a1State"> <b-form-input step="any" type="number" min="1" max="100" v-model="p2a1" :state="p2a1State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Cara penyampaian pesan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat menyempaikan materi dengan sangat sistematis dan mudah dimengerti</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat menyempaikan materi dengan sistematis dan mudah dimengerti</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat menyempaikan materi dengan cukup sistematis dan mudah dimengerti</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar menyempaikan materi dengan tidak sistematis sehingga sulit dimengerti</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a2State"> <b-form-input step="any" type="number" min="1" max="100" v-model="p2a2" :state="p2a2State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kualitas Visualisasi paparan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang sangat menarik </span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang cukup menarik</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang biasa saja</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang tidak menarik</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a3State"> <b-form-input step="any" type="number" min="1" max="100" v-model="p2a3" :state="p2a3State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penguasaan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">materi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">yang</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dipresentasikan</span> </td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar sangat menguasai semua materi yang dipaparkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar menguasai sebagian besar materi yang dipaparkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar kurang menguasai semua materi yang dipaparkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar tidak menguasai semua materi yang dipaparkan</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a4State"> <b-form-input step="any" type="number" min="1" max="100" v-model="p2a4" :state="p2a4State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ketepatan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">jawaban</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">atas</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">pertanyaan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">yang</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">diberikan</span> </td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Sangat tepat, dan mampu menjawab semua pertanyaan yang ada</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tepat, dan hampir semua pertanyaan terjawab</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kurang tepat, dan hanya beberapa pertanyaan yang terjawab</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Sama sekalai tidak mampu menjawab pertanyaan dengan tepat</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                      <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Teknik</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">penyusunan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">paparan</span> </td>
                      <td><b-form-group invalid-feedback="Nilai is required" :state="p2b1State"> <b-form-input min="1" max="100" v-model="p2b1" step="any" type="number" :state="p2b1State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax">Menilai kesesuaian dengan template paparan yang sudah ditetapkan</td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan sangat sesuai dengan template yang telah ditetapkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan sebagian besar sudah sesuai dengan template yang telah ditetapkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan banyak yang tidak sesuai dengan template yang telah ditetapkan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan tidak sesuai dengan template yang telah ditetapkan</span></td>
                    </tr>
                    <tr>
                      <td><b-form-group invalid-feedback="Nilai is required" :state="p2b2State"> <b-form-input min="1" max="100" v-model="p2b2" step="any" type="number" :state="p2b2State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penggunaan properti (media komunikasi) presentasi.</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan sangat interaktif dengan memadukan berbagai media komunikasi (Audio, Visual, peraga, dll)</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan interaktif dengan memadukan dengan media komunikasi (Audio, Visual, peraga, dll)</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan cukup interaktif dengan memadukan dengan media komunikasi (Audio, Visual, peraga, dll)</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan tidak interaktif dan tidak menggunakan media komunikasi pendukung (Audio, Visual, peraga, dll)</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">3</span></td>
                      <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">paparan</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Alokasi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span></td>
                      <td><b-form-group invalid-feedback="Nilai is required" :state="p3a1State"> <b-form-input type="number" v-model="p3a1" step="any" min="1" max="100" :state="p3a1State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">ketepatan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">paparan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">sesuai</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dengan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">yang</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">diberikan</span> </td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat memanfaatkan waktu dengan sangat optimal</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat memanfaatkan waktu dengan baik</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar kurang dapat memanfaatkan waktu dengan optimal</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar tidak dapat memanfaatkan waktu dengan optimal</span></td>
                    </tr>
                    <tr>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Efisiensi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span></td>
                      <td><b-form-group invalid-feedback="Nilai is required" :state="p3b1State"> <b-form-input type="number" v-model="p3b1" step="any" min="1" max="100" :state="p3b1State" required ></b-form-input></b-form-group></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">efsiensi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dalam</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">menjawab</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">setiap</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">pertanyaan/</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">tanggapan</span> </td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara sangat baik dengan jawaban optimal</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara baik dengan jawaban baik</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara cukup baik dengan jawaban cukup baik</span></td>
                      <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara kurang baik sehingga jawaban yang diberikan kurang optimal</span></td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="inputnilai(dataprovinsi.idpenilai,dataprovinsi.idpeserta)"
                    >
                        Simpan
                    </b-button>
                    <b-button
                        variant="danger"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>

                <b-modal
                v-model="show1"
                id="modal-edit"
                ref="modal"
                size="xl"
                title="Form Ubah Nilai"
                @show="resetModal"
                :no-close-on-backdrop="true"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="ubahnilai">
                <div class="alert alert-primary d-flex align-items-center" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                  </svg>
                  <div>
                    <span style="padding-right:20px"> </span> Masukkan Penilaian Anda dengan rentang 1-100
                  </div>
                </div>
                
                <div style="overflow-x:auto;">
                  <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="tg-1wig" colspan="2">Kriteria Penilaian</th>
                      <th class="tg-1wig" colspan="2">Indikator Penilaian</th>
                      <th>Nilai</th>
                      <th class="tg-0lax">Tolak Ukur</th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Sangat </span><span style="font-weight:700">Bagus (85 sd 100)</span></th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Bagus </span><span style="font-weight:700">(70 sd 84,9)</span></th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Kurang</span><span style="font-weight:700"> Bagus (65 sd 69,9)</span><br></th>
                      <th class="tg-0lax"><span style="font-weight:700;font-style:normal;text-decoration:none">Tidak </span><span style="font-weight:700">Bagus (dibawah 65)</span><br></th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">1</span></td>
                        <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penyajian</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">data</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">informasi</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kelengkapan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">data</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">informasi</span> </td>
                        <td ><b-form-group invalid-feedback="Nilai is required" :state="p1a1State"> <b-form-input type="number" step="any" style="width:100px" v-model="dataprovinsi.p1a1" min="1" max="100" :state="p1a1State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kelengkapan bahan paparan atas kriteria yang ada dalam Penilaian Mandiri </span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kriteria dan indikator penilaian telah dipaparkan dengan jelas</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua kriteria dipaparkan, namun ada sebagian indikator yang tidak dipaparkan dengan jelas</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ada sebagian kecil baik kriteria maupun indikator penilaian yang tidak dipaparkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Sebagian besar kriteria dan indikator penilaian tidak dipaparkan dengan jelas</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Validitas data dan informasi</span></td>
                        <td><b-form-group invalid-feedback="Nilai is required" :state="p1b1State"> <b-form-input type="number" v-model="dataprovinsi.p1b1" step="any" min="1" max="100" :state="p1b1State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai kebenaran atas kriteria penilaian mandiri yang dipaparkan dan kebenaran bukti-bukti dokumen dan aktivitas yang telah dilakukan (arsip konvensional, arsip digital, maupun bukti fisik dalam paparan)</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan sangat valid, sehingga dapat menunjukkan kebenaran seluruh kriteria dan indikator penilaian kinerja. </span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan valid, sehingga dapat menunjukkan kebenaran seluruh atau sebagian kriteria dan atau indikator penilaian kinerja. </span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan cukup valid, sehingga dapat menunjukkan kebenaran seluruh atau sebagian kriteria dan atau indikator penilaian kinerja.</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua data dan informasi  yang mendukung paparan kurang valid, sehingga kurang dapat menunjukkan kebenaran kriteria dan indikator penilaian kinerja. </span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax" rowspan="6"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">2</span></td>
                        <td class="tg-0lax" rowspan="6"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemaparan</span></td>
                        <td class="tg-0lax" rowspan="4"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                        <td class="tg-0lax" rowspan="4"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Teknik pemaparan</span> </td>
                        <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a1State"> <b-form-input step="any" type="number" min="1" max="100" v-model="dataprovinsi.p2a1" :state="p2a1State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Cara penyampaian pesan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat menyempaikan materi dengan sangat sistematis dan mudah dimengerti</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat menyempaikan materi dengan sistematis dan mudah dimengerti</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat menyempaikan materi dengan cukup sistematis dan mudah dimengerti</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar menyempaikan materi dengan tidak sistematis sehingga sulit dimengerti</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a2State"> <b-form-input step="any" type="number" min="1" max="100" v-model="dataprovinsi.p2a2" :state="p2a2State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kualitas Visualisasi paparan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang sangat menarik</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang cukup menarik</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang biasa saja</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Paparan disampaikan dengan visualisasi yang tidak menarik</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a3State"> <b-form-input step="any" type="number" min="1" max="100" v-model="dataprovinsi.p2a3" :state="p2a3State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penguasaan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">materi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">yang</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dipresentasikan</span> </td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar sangat menguasai semua materi yang dipaparkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar menguasai sebagian besar materi yang dipaparkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar kurang menguasai semua materi yang dipaparkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar tidak menguasai semua materi yang dipaparkan</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required" :state="p2a4State"> <b-form-input step="any" type="number" min="1" max="100" v-model="dataprovinsi.p2a4" :state="p2a4State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ketepatan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">jawaban</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">atas</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">pertanyaan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">yang</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">diberikan</span> </td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Sangat tepat, dan mampu menjawab semua pertanyaan yang ada</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tepat, dan hampir semua pertanyaan terjawab</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kurang tepat, dan hanya beberapa pertanyaan yang terjawab</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Sama sekalai tidak mampu menjawab pertanyaan dengan tepat</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                        <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Teknik</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">penyusunan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">paparan</span> </td>
                        <td><b-form-group invalid-feedback="Nilai is required" :state="p2b1State"> <b-form-input min="1" max="100" v-model="dataprovinsi.p2b1" step="any" type="number" :state="p2b1State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax">Menilai kesesuaian dengan template paparan yang sudah ditetapkan</td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan sangat sesuai dengan template yang telah ditetapkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan sebagian besar sudah sesuai dengan template yang telah ditetapkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan banyak yang tidak sesuai dengan template yang telah ditetapkan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Materi yang dipaparakan tidak sesuai dengan template yang telah ditetapkan</span></td>
                      </tr>
                      <tr>
                        <td><b-form-group invalid-feedback="Nilai is required" :state="p2b2State"> <b-form-input min="1" max="100" v-model="dataprovinsi.p2b2" step="any" type="number" :state="p2b2State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penggunaan properti (media komunikasi) presentasi.</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan sangat interaktif dengan memadukan berbagai media komunikasi (Audio, Visual, peraga, dll)</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan interaktif dengan memadukan dengan media komunikasi (Audio, Visual, peraga, dll)</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan cukup interaktif dengan memadukan dengan media komunikasi (Audio, Visual, peraga, dll)</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Keseluruhan paparan tidak interaktif dan tidak menggunakan media komunikasi pendukung (Audio, Visual, peraga, dll)</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">3</span></td>
                        <td class="tg-0lax" rowspan="2"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">paparan</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Alokasi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span></td>
                        <td><b-form-group invalid-feedback="Nilai is required" :state="p3a1State"> <b-form-input type="number" v-model="dataprovinsi.p3a1" step="any" min="1" max="100" :state="p3a1State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">ketepatan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">paparan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">sesuai</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dengan</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">yang</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">diberikan</span> </td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat memanfaatkan waktu dengan sangat optimal</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar dapat memanfaatkan waktu dengan baik</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar kurang dapat memanfaatkan waktu dengan optimal</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pemapar tidak dapat memanfaatkan waktu dengan optimal</span></td>
                      </tr>
                      <tr>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Efisiensi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span></td>
                        <td><b-form-group invalid-feedback="Nilai is required" :state="p3b1State"> <b-form-input type="number" v-model="dataprovinsi.p3b1" step="any" min="1" max="100" :state="p3b1State" required ></b-form-input></b-form-group></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">efsiensi</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">waktu</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">dalam</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">menjawab</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">setiap</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">pertanyaan/</span> <span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">tanggapan</span> </td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara sangat baik dengan jawaban optimal</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara baik dengan jawaban baik</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara cukup baik dengan jawaban cukup baik</span></td>
                        <td class="tg-0lax"><span style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Waktu yang diberikan untuk menjawab pertanyaan, dapat dimanfaatkan secara kurang baik sehingga jawaban yang diberikan kurang optimal</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <!-- <p class="float-left">Modal Footer Content</p> -->
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="ubahnilai(dataprovinsi.id)"
                    >
                        Simpan
                    </b-button>
                    <b-button
                        variant="danger"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show1=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>

                <!-- lihat catatan -->
                <div class="modal fade catatan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                    <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>File</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{row.judul}}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{row.tipe}}</div>
                                                    <div v-else style="color:blue">{{row.tipe}}</div>
                                                </td>
                                                <td>
                                                    <div v-if="row.file != null">
                                                        <a :href="row.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-catatan" @click="getPerCatatan(row.id)" style="margin:5px" class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <div class="modal fade" id="modal-catatan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                   

                                    <div class="form-group row">
                                        <label class="col-4">Tipe</label>
                                        <div class="col-8">
                                            <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.judul" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Lembaga</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.lembaga" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker disabled  v-model="getCatatan.tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker disabled  v-model="getCatatan.tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <div v-html="getCatatan.deskripsi"></div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.sumber" class="form-control"/>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <!-- <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file> -->
                                            <div v-if="getCatatan.file !== null">
                                                <a :href="getCatatan.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    <!-- </div>
  </div> -->


  
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import Swal from "sweetalert2";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        tahun: new Date().getFullYear(),
        user: JSON.parse(localStorage.getItem('user')),
        totskor:[],
        idruangan:'',
        daerah:'',
        ruanganpenilai:[],
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        noCatatan:'',
        timpenilai:[],
        p1a1State: null,
        p1b1State: null,
        p2a1State: null,
        p2a2State: null,
        p2a3State: null,
        p2a4State: null,
        p2b1State: null,
        p2b2State: null,
        p3a1State: null,
        p3b1State: null,
        p1a1:'',
        p1b1:'',
        p2a1:'',
        p2a2:'',
        p2a3:'',
        p2a4:'',
        p2b1:'',
        p2b2:'',
        p3a1:'',
        p3b1:'',
        dataprovinsi:[],
        show:false,
        show1:false,
        catatann:[],
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
      }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Paparan Pemda Provinsi" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    // mengload data nomine apa aja yang akan di nilai masing2 user
    loaddata(){
      this.totskor = null;
      axios.get(this.url+"/peserta_by_penilai_pemaparan?idpenilai="+this.user.id+"&tipe=Provinsi&tahun="+this.tahun, {
            headers: {
                'xth': this.token
            }
        })
      .then(response => {
          var nominee = response.data.data;
          this.totskor = nominee;
          this.initDatatable();
      }).catch(error=>{
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          Swal.fire({
          title: error.response.data,
          text: "",
          icon: 'error',
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
          }).then((result) => {
              if (result.isConfirmed) {
                  this.$store
                  .dispatch(LOGOUT)
                  .then(() => this.$router.push({ name: "login" }));
              }
          })
        } else {
          console.log(error)
          
        }
          return error
          });
    },
    // mendapatkan catatan masing2 ptsp
    getcatatan(idptsp){
        $('#example2').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_provinsi?filter=idptsp,=,"+idptsp+";tahun,=,"+this.tahun, {
              headers: {
                  'xth': this.token
              }
          })
            .then(response => {
                var catatannomine = response.data.data;
                this.catatann = catatannomine;
                this.initDatatable2()
            }).catch(error=>{
                return error
        });
    },
    // melihat detail catatan ptsp
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_provinsi?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
        }).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idptsp = response.data.data[0].idptsp;
                this.getCatatan.file = response.data.data[0].file;
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    // mendapatkan data ptsp
    getdataprovinsi(penilai, peserta){
      this.dataprovinsi.idpenilai = penilai
      this.dataprovinsi.idpeserta = peserta
    },

    // mendapatkan data nilai ptsp
    getdataprovinsiedit(id){
      axios.get(this.url+"/tabel_nilai_peserta_pemaparan?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            this.dataprovinsi = response.data.data[0];
        }).catch(error=>{
            return error
            });
    },

    // ubah nilai
    ubahnilai(id) {
        axios.put(this.url+`/tabel_nilai_peserta_pemaparan/${id}`,
            {
                p1a1: this.dataprovinsi.p1a1,
                p1b1: this.dataprovinsi.p1b1,
                p2a1: this.dataprovinsi.p2a1,
                p2a2: this.dataprovinsi.p2a2,
                p2a3: this.dataprovinsi.p2a3,
                p2a4: this.dataprovinsi.p2a4,
                p2b1: this.dataprovinsi.p2b1,
                p2b2: this.dataprovinsi.p2b2,
                p3a1: this.dataprovinsi.p3a1,
                p3b1: this.dataprovinsi.p3b1,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            this.$bvToast.toast('Gagal Diedit', {
              title: `Failed `,
              variant: `danger`,
              solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
        })
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.p1a1State = valid
        this.p1b1State= valid
        this.p2a1State= valid
        this.p2a2State= valid
        this.p2a3State= valid
        this.p2a4State= valid
        this.p2b1State= valid
        this.p2b2State= valid
        this.p3a1State= valid
        this.p3b1State= valid
        return valid
      },
    resetModal() {
        this.p1a1State= null
        this.p1b1State= null
        this.p2a1State= null
        this.p2a2State= null
        this.p2a3State= null
        this.p2a4State= null
        this.p2b1State= null
        this.p2b2State= null
        this.p3a1State= null
        this.p3b1State= null
        this.p1a1=''
        this.p1b1=''
        this.p2a1=''
        this.p2a2=''
        this.p2a3=''
        this.p2a4=''
        this.p2b1=''
        this.p2b2=''
        this.p3a1=''
        this.p3b1=''

      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.inputnilai()
      },

    // input nilai
    inputnilai(penilai, peserta) {
        const p1a1= this.p1a1;
        const p1b1= this.p1b1;
        const p2a1= this.p2a1;
        const p2a2= this.p2a2;
        const p2a3= this.p2a3;
        const p2a4= this.p2a4;
        const p2b1= this.p2b1;
        const p2b2= this.p2b2;
        const p3a1= this.p3a1;
        const p3b1= this.p3b1;
        const tahun = this.tahun;
        const idpenilai = penilai;
        // const penilai = this.user.name;
        const idpeserta = peserta;
        // const peserta = daerah;
        if (!this.checkFormValidity()) {
          return
        }
        
        axios
            .post(this.url+"/tabel_nilai_peserta_pemaparan",
            {
                p1a1,
                p1b1,
                p2a1,
                p2a2,
                p2a3,
                p2a4,
                p2b1,
                p2b2,
                p3a1,
                p3b1,
                tahun,
                idpenilai,
                idpeserta,
            }, {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.resetModal()
                this.getdata()
                
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
              title: `Failed `,
              variant: `danger`,
              solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-input')
        })
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
};
</script>
